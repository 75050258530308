
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Table from "@/components/Table.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "admin-users",
  components: {
    Table,
    Field,
  },
  async mounted() {
    this.init();
  },
  data() {
    console.log();
    const store = useStore();
    const route = useRoute();
    // const pckg = route.params.pckg ?? null;
    const url = process.env.VUE_APP_API_URL + "/users/list-ldap"; // ⭕️
    const { n, t } = useI18n();

    const filter = {
      byStatus: "",
      byBranch: "",
      byRole: "",
    };

    const statuses = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "1",
      },
      {
        label: "Inactive",
        value: "0",
      },
    ];
    const roles = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Financial Advisor",
        value: 4,
      },
      {
        label: "Branch Head",
        value: 5,
      },
      {
        label: "Viewer",
        value: 6,
      },
      {
        label: "Management",
        value: 7,
      },
    ];

    const branches = [];

    return {
      // pckg,
      store,
      ajax: url,
      filter,
      statuses,
      roles,
      branches,
      order: [[0, "asc"]],
      columns: [
        {
          data: "id",
          title: "No",
          defaultContent: "-",
          className: "text-center",
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          data: "name",
          defaultContent: "-",
          title: "Name",
        },
        {
          data: "email",
          title: "Email",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "branch",
          title: "Branch",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "role",
          title: "Role",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "status",
          title: "Status",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "action",
          title: "Action",
        },
      ],
    };
  },
  setup(props) {
    const { t } = useI18n();

    return { t };
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("Users", ["Administration"]);
      this.store.dispatch(Actions.GET_BRANCHES).then(() => {
        this.branches = this.store.getters.getBranchesData;
      });
    },
    dtFilter() {
      const table = this.$refs.tableUsers as any;
      let urlAjax = this.ajax;
      const queryParams = [];

      if (this.filter.byStatus) {
        queryParams.push(`filterByStatus=${this.filter.byStatus}`);
      }

      if (this.filter.byBranch) {
        queryParams.push(`filterByBranch=${this.filter.byBranch}`);
      }

      if (this.filter.byRole) {
        queryParams.push(`filterByRole=${this.filter.byRole}`);
      }

      if (queryParams.length > 0) {
        urlAjax += "?" + queryParams.join("&");
      }

      table?.dataTable.ajax.url(urlAjax).load().draw();
    },
  },
});
